import { useState } from 'react'

import { isSSR } from '@/utils/misc'
import { ICookieOptions, readCookie, writeCookie } from '@/utils/storage'

export enum COOKIE_NAME {
  CONSENT = 'cookie_consent',
  GUIDANCE = 'guidance',
  AREA_SELECTED = 'area_selected',
  PREFER_SIGNUP = 'prefer_signup',
  REDIRECT_TO = 'redirect_to',
  VOUCHERCODE_PREFILL = 'vouchercode_prefill',
}

export interface IUseCookieReturnType<T = string> {
  cookieValue: T
  updateCookie: (value: T, options?: ICookieOptions) => void
  deleteCookie: (options?: ICookieOptions) => void
}

export const useCookie = <T extends string>(
  cookieName: string,
  initialValue: T = null
): IUseCookieReturnType<T> => {
  const [cookieValue, setCookieValue] = useState<T>(
    () => (!isSSR && readCookie<T>(cookieName)) || initialValue
  )

  const updateCookie = (value: T, options: ICookieOptions = {}) => {
    setCookieValue(value)
    writeCookie(cookieName, value, options)
  }

  const deleteCookie = (options: ICookieOptions = {}) => {
    writeCookie(cookieName, '', { ...options, validDays: -1 })
    setCookieValue(null)
  }

  return { cookieValue, updateCookie, deleteCookie }
}
