import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { Description, EditoSlice, Title } from '@/components/_layout/EditoSlice'
import { IPropertyValueArea } from '@/types/main'

interface IProps {
  area: IPropertyValueArea
}

export const AreaEditoSlice: React.FC<IProps> = ({ area }) => {
  const intl = useIntl()

  return (
    <EditoSlice
      imagePath={`/area_picture/edito/${area.slug}.jpg`}
      imageAlt={intl.formatMessage(
        {
          defaultMessage: 'Posture de yoga à {areaTitle}',
          description: 'AreaEditoSlice: image alt',
        },
        { areaTitle: area.title }
      )}
    >
      <FormattedMessage
        tagName={Title}
        defaultMessage="Trouvez un cours de yoga près de chez vous à {areaTitle} !"
        description="AreaEditoSlice: title"
        values={{ areaTitle: area.title }}
      />
      <FormattedMessage tagName={Description} {...descriptionMessages[area.slug]} />
    </EditoSlice>
  )
}

const descriptionMessages = defineMessages({
  paris: {
    defaultMessage: `
      Profitez de notre offre de cours de yoga dans tout Paris : nous vous accueillons dans de nombreux lieux chaque
      semaine pour pratiquer en studio avec les meilleurs professeurs de yoga parisiens ! Déroulez votre tapis, et
      découvrez les bienfaits de toutes nos disciplines : yoga vinyasa, yoga hatha, yin yoga...  Trouvez l’adresse
      la plus proche de chez vous ou de votre travail pour pratiquer sans contrainte, à un tarif accessible, avec nos
      professeurs experts et dans une ambiance bienveillante. #yogaforall
    `,
    description: 'AreaEditoSlice: content Paris',
  },
  lyon: {
    defaultMessage: `
      OLY Be vous propose des cours de yoga, fitness et bien-être à Lyon pour tous les niveaux et avec des tarifs adaptés
      à votre budget. Embarquez votre tapis et rencontrez nos professeurs certifiés lors d’une de nos séances de yoga près
      de chez vous. Yoga vinyasa, hatha yoga, yin yoga... découvrez les bienfaits de nos différentes pratiques, apprenez
      à vous relaxer et respirer en plein cœur de Lyon. Entre croix-rousse et Bellecour, la Part-dieu et Fourvière, ou
      encore près du parc de la tête d’or, nos professeurs lyonnais sont à proximité de chez vous et vous accompagneront
      au mieux dans votre pratique. Nos cours s’adaptent à tous les niveaux, et toutes les envies : prévenir les blessures,
      partager un moment convivial, faire une pause, se muscler... On a hâte de vous rencontrer ! #yogaforall
    `,
    description: 'AreaEditoSlice: content Lyon',
  },
  'paris-1': {
    defaultMessage: `
      Profitez de notre offre de cours de yoga, fitness et bien-être dans le 1er arrondissement de Paris (75001) ! Nous
      proposons différentes pratiques de yoga (hatha, vinyasa, yin) pour tous les niveaux, ainsi que d’autres activités
      sportives et bien-être. Du jardin des tuileries au métro Châtelet en passant par la place du palais royal, nos studios
      sont accessibles facilement et nos tarifs vous permettent d’être flexibles ! Découvrez également dans le 1er
      arrondissement notre superbe studio en plein cœur du Carrousel du Louvre, à quelques pas de la Comédie Française, vous
      y rencontrerez nos professeurs certifiés et qui sont là pour prendre soin de vous. Chaque cours est différent et vous
      permettra de tester des postures variées, tout en profitant d’un moment d’interaction avec les autres élèves. Embarquez
      votre tapis et lancez-vous ! Les quartier des Halles et de Pyramides à proximité vous offrent une jolie promenade sur
      la pause déjeuner et le 1er arrondissement de Paris ne manque pas de bonnes adresses pour se restaurer en toute
      simplicité avant ou après le cours !
    `,
    description: 'AreaEditoSlice: content Paris 1',
  },
  'paris-2': {
    defaultMessage: `
      Découvrez nos cours de yoga, fitness et bien-être dans le 2ème arrondissement de Paris (75002). Envie de découvrir le
      yoga vinyasa, le yin, l’ashtanga ou encore le hatha yoga ? Nos professeurs de yoga testés et certifiés sont là pour vous
      faire découvrir nos différentes activités et décortiquer toutes les postures du yoga. Nos différents studios rue montmartre,
      dans le quartier du sentier ou près de la rue réaumur sébastopol vous accueillent chaque semaine : pour du yoga mais aussi
      du renforcement musculaire, de la danse ou encore de la méditation. Profitez d’un moment pour prendre soin de votre corps,
      que ce soit une pratique douce ou une pratique physique. Chaque cours est un moment convivial, partagez une séance avec vos
      voisins ou vos collègues en pratiquant près de chez vous ou du bureau ! Nos cours sont adaptés à tous les niveaux et toutes
      les envies #yogaforall
    `,
    description: 'AreaEditoSlice: content Paris 2',
  },
  'paris-3': {
    defaultMessage: `
      Pratiquez le yoga, le fitness, ou découvrez de nouvelles disciplines bien-être grâce à OLY Be dans le 3ème arrondissement
      de Paris (75003) ! Entre république, le canal saint-martin et la place de la Bastille, nous proposons de nombreux cours de
      yoga vinyasa, hatha ou encore yin yoga pour vous permettre de vous sentir bien ! Rencontrez nos professeurs certifiés et
      testés, et profitez des bienfaits du yoga tout près de chez vous. Embarquez votre tapis et prenez enfin un temps pour vous !
    `,
    description: 'AreaEditoSlice: content Paris 3',
  },
  'paris-4': {
    defaultMessage: `
      Profitez d’une balade dans le quartier du Marais pour assister à l’un de nos cours de yoga : vinyasa, hatha, ashtanga ou
      encore yin. Nous proposons des disciplines très variées, du yoga au fitness en passant par la méditation et la danse !
      Rencontrez nos meilleurs professeurs bienveillants et prenez un moment pour vous étirer, renforcer votre corps, ou tout
      simplement vous relaxer. Découvrez nos différents studios tout proche de votre domicile dans le 4ème arrondissement de
      Paris (75004), nos professeurs pédagogues vous feront travailler de nouvelles postures, entre mouvement et respiration,
      vous pourrez aligner votre corps et votre esprit.
    `,
    description: 'AreaEditoSlice: content Paris 4',
  },
  'paris-5': {
    defaultMessage: `
      Pratiquez le yoga ou le fitness près de chez vous à Paris 5 (75005). Yoga vinyasa, Yoga hatha, Renforcement musculaire,
      Full Body training… de nombreuses disciplines sont enseignées dans nos studios du 5ème arrondissement ! De quoi vous
      dépenser, travailler votre corps mais également votre mental :) Profitez d’une promenade dans ce bel arrondissement pour
      vous accorder un moment pour vous : le yoga est idéal pour cela ! Qu’il soit dynamique ou doux, il vous permettra de vous
      détendre, de travailler les asanas et votre respiration. Nos professeurs bienveillants vous accompagneront pour vous offrir
      ce moment de bien-être.
    `,
    description: 'AreaEditoSlice: content Paris 5',
  },
  // 'paris-6': {
  //   defaultMessage: '',
  //   description: 'AreaEditoSlice: content Paris 6',
  // },
  // 'paris-7': {
  //   defaultMessage: '',
  //   description: 'AreaEditoSlice: content Paris 7',
  // },
  'paris-8': {
    defaultMessage: `
      Proche de l’arc de triomphe, des champs elysées ou du parc monceau, nous proposons différents studios pour pratiquer dans
      le 8ème arrondissement de Paris ! Entre 2 passages dans ces jolis quartiers, prenez le temps de faire une pause bien-être
      et de prendre un cours de yoga. Hatha, Vinyasa, Yin yoga ou encore Ashtanga, que vous souhaitiez un cours de yoga dynamique
      ou doux, vous trouverez forcément ce qu’il vous faut ! Choisissez sur le planning le meilleur moment de la journée,
      rencontrez nos supers professeurs certifiés, et posez vous sur votre tapis. Concentrez-vous sur votre respiration, les
      mouvements de votre corps, et le calme de votre esprit après la pratique. Les postures s’enchaineront, certaines pour vous
      étirer comme le stretching, d’autres pour vous renforcer à l’image du renforcement musculaire.
    `,
    description: 'AreaEditoSlice: content Paris 8',
  },
  'paris-9': {
    defaultMessage: `
      Profitez de nos cours de yoga, fitness, méditation et bien-être dans le 9ème arrondissement de Paris (75009).
      Nous proposons différents styles de yoga : hatha yoga, vinyasa, ashtanga, yin et même du kundalini yoga !
      Grâce à notre offre flexible et nos tarifs accessibles (à partir de 9 euros par cours) à vous le cours de yoga
      suivi d’une virée shopping aux Grands Magasins (Galeries Lafayette, Printemps Haussmann) ou encore une sortie à
      l’Opéra Garnier ou dans un des nombreux théâtres parisiens (Théâtre Mogador, la Comédie de Paris, Théâtre de
      Paris…) Tapis de yoga sous le bras, profitez de Paris 9 et sa riche culture, de ses nombreux restaurants, de ses
      rues haussmanniennes, et inscrivez-vous en un clic à votre cours de yoga préféré via notre site ou notre
      application ! Vous serez toujours accueilli par nos professeurs certifiés et qui vous guideront avec douceur et
      bienveillance dans des studios qui sont de véritables espaces de bien-être où vous pourrez dire adieu au stress !
    `,
    description: 'AreaEditoSlice: content Paris 9',
  },
  'paris-10': {
    defaultMessage: `
      Glissez votre tapis de yoga dans votre sac et rendez-vous à votre cours de yoga, fitness, méditation ou autre
      pratique holistique dans le 10ème arrondissement de Paris, grâce à OLY Be ! Nous vous proposons des cours
      accessibles à tous les niveaux et à toutes les envies : hatha yoga, yin yoga, yoga prénatal, méditation, pilates…
      et plus encore ! En sortant du bureau ou avant un brunch entre amis, profitez de notre offre de cours variée pour
      faire une pause bien-être accompagné de nos professeurs expérimentés, à l’écoute et toujours bienveillants.
      Que vous préfériez un vinyasa dynamique avant d’aller boire un verre et dîner rue du faubourg Saint Denis, ou
      plutôt un cours de stretching après un pique-nique sur les quais du Canal Saint-Martin ou dans le Jardin
      Villemin, OLY Be a tout ce qu’il faut pour vous faire du bien et couper avec le stress parisien ! La place de la
      République et ses nombreuses lignes de métro rendent ce quartier et nos studios hyper accessibles :
      vous n’avez plus qu’à réserver votre prochain cours en quelques clics via notre site ou notre application mobile
      (disponible sur Apple et Android). A très vite sur le tapis !
    `,
    description: 'AreaEditoSlice: content Paris 10',
  },
  'paris-11': {
    defaultMessage: `
      Faites le plein d’énergie en participant à l’un de nos cours de yoga dans le 11ème arrondissement de Paris (75011) !
      Profitez d’un passage dans ce quartier animé pour prendre une pause pour votre corps et votre esprit. Grâce à l’enseignement
      de nos professeurs certifiés, découvrez de nombreuses pratiques : yoga vinyasa, hatha yoga, yoga kundalini, yin yoga...
      Choisissez l’horaire qui vous convient et assistez à l’une de nos séances : méditation, relaxation, respiration, postures
      et asanas... notre équipe vous accompagne à chaque moment de votre pratique, dans une ambiance bienveillante. Métro
      parmentier, bastille ou république, découvrez nos nombreux studios de yoga à Paris 11 et profitez de nos tarifs accessibles
      pour découvrir les bienfaits d’une pratique régulière.
    `,
    description: 'AreaEditoSlice: content Paris 11',
  },
  'paris-12': {
    defaultMessage: `
      Yoga vinyasa, hatha yoga, yin yoga, yoga nidra... que ce soit pour une pratique douce ou plus dynamique, découvrez nos cours
      de yoga dans le 12ème arrondissement de Paris (75012). Nos coachs et professeurs de yoga certifiés vous attendent pour
      travailler vos muscles en profondeur, découvrir de nouvelles postures, tester des exercices de respiration, se concentrer sur
      l’instant présent... nos séances s’adaptent à tous les besoins et tous les niveaux. Cours réguliers ou ateliers ponctuels,
      découvrez nos tarifs accessibles et réservez à l’adresse la plus proche de chez vous. De la Bastille au château de Vincennes,
      en passant par la place de la Nation ou le parc de Bercy : cherchez la salle la plus proche de chez vous et trouvez la
      discipline qui vous intéresse entre yoga fitness et bien-être. Partagez un moment de relaxation en rencontrant également
      d’autres élèves : peu importe votre âge ou votre souplesse, nos cours sont ouverts à tous les publics. #yogaforall
    `,
    description: 'AreaEditoSlice: content Paris 12',
  },
  'paris-13': {
    defaultMessage: `
      Pratiquez le yoga, le fitness ou la méditation près de chez vous dans le 13ème arrondissement de Paris (75013). Nos
      tarifs sont accessibles et notre offre flexible pour vous permettre de trouver facilement le cours de yoga dont vous
      avez besoin. Que ce soit pour une pratique dynamique (yoga hatha, yoga vinyasa) ou plus douce (yin yoga, relaxation,
      méditation) : nos professeurs certifiés sont là pour vous accompagner à chaque séance. Choisissez l’heure qui vous
      convient et partagez-nous votre besoin : renforcement musculaire, gestion du stress, prévention des douleurs... quel
      que soit votre niveau, nous avons un cours qui vous correspond. Métro Olympiades, rue Tolbiac, Place d’italie, Porte
      d'Italie ou encore Bibliothèque François Mitterand, nous proposons des cours partout dans Paris 13, pour pratiquer
      facilement à côté de chez vous.
    `,
    description: 'AreaEditoSlice: content Paris 13',
  },
  'paris-14': {
    defaultMessage: `
      Découvrez nos cours de yoga, fitness et bien-être dans le 14ème arrondissement de Paris (75014) ! Vous cherchez des cours
      de yoga près de chez vous ? OLY Be vous propose de nombreux styles de yoga différents : yoga vinyasa, yin yoga, yoga
      thérapie, ashtanga ou encore pilates. Peu importe votre niveau, nos cours sont accessibles à tous : débutants ou confirmés,
      jeunes ou plus âgés ! Nos professeurs certifiés vous accompagnent pour vous faire progresser à chaque séance, parce qu’une
      pratique régulière est la clé pour se détendre et prendre soin de son corps. Cité universitaire, Parc montsouris, Gare
      Montparnasse ou encore Alésia : retrouvez nos cours à proximité de chez vous, il vous suffit d’un tapis et de quelques
      minutes pour réserver votre prochain moment de détente.
    `,
    description: 'AreaEditoSlice: content Paris 14',
  },
  'paris-15': {
    defaultMessage: `
      Découvrez le yoga, le fitness et le bien-être grâce à nos cours dans le quinzième arrondissement de Paris (75015) ! Choisissez
      votre horaire, votre professeur, prenez votre tapis et venez rencontrer notre communauté bienveillante. Nos professeurs testés
      et certifiés vous offrent un enseignement de qualité, en vous faisant découvrir des disciplines variées : yoga dynamique, yoga
      doux, yoga pour débutants... Consultez notre planning de cours et trouvez le lieu le plus pratique pour vous : avec vue sur la
      tour Eiffel depuis le champs de mars, proche des boutiques à Beaugrenelle ou rue du commerce, ou encore en extérieur au Parc
      André Citroën. Offrez-vous une pause relaxante dans votre semaine, nos cours démarrent souvent par une méditation puis des
      postures de yoga pour renforcer le corps et gagner en souplesse, des exercices de respiration pour une meilleure santé, le tout
      dans le partage et la bonne humeur !
    `,
    description: 'AreaEditoSlice: content Paris 15',
  },
  // 'paris-16': {
  //   defaultMessage: '',
  //   description: 'AreaEditoSlice: content Paris 16',
  // },
  'paris-17': {
    defaultMessage: `
      Participez à l’un de nos cours de yoga, fitness ou bien-être dans le 17ème arrondissement de Paris (75017). Profitez de nos
      tarifs accessibles pour découvrir les bienfaits du yoga et de ses différentes pratiques : yoga vinyasa, hatha yoga, yin
      yoga... vous trouverez forcément le cours qu’il vous faut. Nos professeurs certifiés sont là pour vous accompagner : débutants
      ou confirmés, peu importe votre niveau nos cours s’adaptent à vos besoins ! A quelques pas de chez vous, près du métro Ternes,
      du square des batignolles ou du parc Martin Luther King, nos nombreux studios vous accueillent chaque semaine pour vous offrir
      une expérience relaxante et inspirante. Découvrez le planning des prochains cours et réservez votre première session de yoga à
      Paris 17 !
    `,
    description: 'AreaEditoSlice: content Paris 17',
  },
  'paris-18': {
    defaultMessage: `
      Envie de tester un cours de yoga ? Découvrez tous nos cours dans le 18ème arrondissement de Paris (75018) : yoga, fitness,
      bien-être, venez travailler votre corps et votre mental dans une ambiance bienveillante et décontractée. Nos professeurs testés
      et certifiés sont là pour vous accompagner sur le tapis chaque semaine. Découvrez notre planning et nos activités près de chez
      vous : dans les jolies rues de Montmartre proche du sacré cœur, à quelques pas de la gare du nord ou des puces de Clignancourt,
      trouvez l’adresse la plus proche de votre domicile. Yoga vinyasa, hatha yoga, yin yoga, ashtanga... venez travailler votre souplesse
      et rencontrer d’autres élèves. Quelque soit votre niveau, débutants ou confirmés, nos cours s’adaptent à vos besoins en vous
      offrant un accompagnement de qualité grâce à nos différents professeurs.
    `,
    description: 'AreaEditoSlice: content Paris 18',
  },
  // 'paris-19': {
  //   defaultMessage: '',
  //   description: 'AreaEditoSlice: content Paris 19',
  // },
  // 'paris-20': {
  //   defaultMessage: '',
  //   description: 'AreaEditoSlice: content Paris 20',
  // },
})

export const COMPLETED_AREA_LANDING_PAGES = Object.keys(descriptionMessages) // TODO: delete when all areas landing pages are done
