import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { ContainerError, ContainerLoading } from '@/components/_layout/ContainerMessage'
import { NextLink } from '@/components/_shared/NextLink'
import { DownChevronIcon } from '@/components/_svg/icons/ChevronIcon'
import { COMPLETED_AREA_LANDING_PAGES } from '@/components/area/_slice/AreaEditoSlice'
import { COOKIE_NAME, useCookie } from '@/hooks/use-cookie'
import { usePropertyValueSharedResults } from '@/hooks/use-property'
import { IAreaKey, IPropertyValueArea } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import {
  buildLessonExploreStudioRoute,
  buildPracticeStudioAreaRoute,
} from '@/utils/route'

export const LessonAreaListSelector: React.FC = () => {
  const {
    propValueListLoading: areaListLoading,
    propValueList: areaList,
    propValueListError: areaListError,
  } = usePropertyValueSharedResults('area')
  const [showAreaList, setShowAreaList] = useState(false)
  const [showAreaChildrenList, setShowAreaChildrenList] = useState(false)
  const { updateCookie: updateCookieAreaKey } = useCookie<IAreaKey>(
    COOKIE_NAME.AREA_SELECTED
  )

  const toggleAreaList = () => {
    setShowAreaList((prevState) => !prevState)
  }

  const toggleAreaChildrenList = () => {
    setShowAreaChildrenList((prevState) => !prevState)
  }

  const persistArea = (area: IPropertyValueArea) => () => {
    updateCookieAreaKey(area.slug as IAreaKey)
  }

  if (areaListLoading) return <ContainerLoading />
  if (areaListError) return <ContainerError />

  return (
    <>
      <FormattedMessage
        tagName={Description}
        defaultMessage="Découvrez tous nos cours par ville pour ne plus jamais manquer une occasion de pratiquer !"
        description="LessonAreaListSelector: description"
      />

      <MobileButton onClick={toggleAreaList}>
        <FormattedMessage
          defaultMessage="Voir toutes les villes"
          description="LessonAreaListSelector: mobile button"
        />
        <DropdownChevronIcon $showAreaList={showAreaList} />
      </MobileButton>

      <AreaList $showAreaList={showAreaList}>
        {areaList.map((area) => {
          if (!area.children.length) {
            return (
              <AreaLi
                area={area}
                handleAreaSelection={persistArea(area)}
                key={area._id}
              />
            )
          }

          return (
            <li key={area._id}>
              <DesktopButton onClick={toggleAreaChildrenList}>
                {area.title}
                <DesktopChevronIcon $showAreaList={showAreaChildrenList} />
              </DesktopButton>
            </li>
          )
        })}

        <AreaChildrenWrapper $showAreaChildrenList={showAreaChildrenList}>
          <AreaChildrenList>
            {areaList
              .filter((area) => area.children.length)
              .map((area) => (
                <React.Fragment key={area._id}>
                  <AreaLi area={area} handleAreaSelection={persistArea(area)} />

                  {area.children.map((areaChild) => (
                    <AreaLi
                      area={areaChild}
                      handleAreaSelection={persistArea(area)}
                      key={areaChild._id}
                    />
                  ))}
                </React.Fragment>
              ))}
          </AreaChildrenList>
        </AreaChildrenWrapper>
      </AreaList>
    </>
  )
}

interface IAreaLiProps {
  area: IPropertyValueArea
  handleAreaSelection: () => void
}

const AreaLi: React.FC<IAreaLiProps> = ({ area, handleAreaSelection }) => (
  <li>
    <StyledNextLink href={buildRoute(area)} onClick={handleAreaSelection}>
      {area.title}
    </StyledNextLink>
  </li>
)

const buildRoute = (area: IPropertyValueArea) => {
  if (COMPLETED_AREA_LANDING_PAGES.includes(area.slug)) {
    return buildPracticeStudioAreaRoute(area.slug)
  }

  if (area.parent) {
    return buildLessonExploreStudioRoute(area.parent.slug, {
      postalCodes: area.misc.postalCode,
    })
  }

  return buildLessonExploreStudioRoute(area.slug)
}

const Description = styled.p`
  text-align: center;
`
const AreaList = styled.ul<{ $showAreaList: boolean }>`
  align-items: center;
  display: ${({ $showAreaList }) => ($showAreaList ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.s};
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    column-gap: ${({ theme }) => theme.spacing.l};
    display: flex;
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`
const SelectButton = styled.button`
  ${({ theme }) => theme.typo.p}
  ${({ theme }) => theme.typo.bold}

  align-items: center;
  color: ${({ theme }) => theme.color.terracota};
  display: flex;
  gap: ${({ theme }) => theme.spacing.s};
`
const MobileButton = styled(SelectButton)`
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: none;
  }
`
const DesktopButton = styled(SelectButton)`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    ${({ theme }) => theme.typo.p}
    ${({ theme }) => theme.typo.bold}
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.s};
  }
`
const DropdownChevronIcon = styled(DownChevronIcon)<{
  $showAreaList: boolean
}>`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  transform: ${({ $showAreaList }) => ($showAreaList ? 'rotate(90deg)' : null)};
  transition: all 300ms;
  width: ${({ theme }) => theme.spacing.s};
`
const StyledNextLink = styled(NextLink)`
  ${({ theme }) => theme.typo.p}
  ${({ theme }) => theme.typo.bold}

  color: ${({ theme }) => theme.color.terracota};
`
const DesktopChevronIcon = styled(DropdownChevronIcon)`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: inline-block;
  }
`
const AreaChildrenWrapper = styled.ul<{ $showAreaChildrenList }>`
  display: block;
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    display: ${({ $showAreaChildrenList }) => ($showAreaChildrenList ? 'block' : 'none')};
  }
`
const AreaChildrenList = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.s};
  justify-content: center;
  margin: 0 auto;
  max-width: 680px;
`
