import React from 'react'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { NextImage } from '@/components/_shared/NextImage'
import { DEVICE_WIDTH } from '@/utils/constants'
import { getLinearShadow } from '@/utils/style'

interface IProps {
  imagePath: string
  imageAlt: string
  className?: string
}

export const EditoSlice: React.FC<React.PropsWithChildren<IProps>> = ({
  imagePath,
  imageAlt,
  children,
  className,
}) => (
  <StyledContainer className={className}>
    <ImageShadow>
      <ImageWrapper>
        <NextImage
          src={imagePath}
          alt={imageAlt}
          sizes={`(max-width: ${DEVICE_WIDTH.MOBILE}px) 80vw, 30vw`}
          imgixPreconfig="capsuleCropFace"
          fill
        />
      </ImageWrapper>
    </ImageShadow>

    <Content>{children}</Content>
  </StyledContainer>
)

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.xxxl};
  margin-top: ${({ theme }) => theme.spacing.xxxl};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 80px;
    margin-top: 80px;
  }
`
export const ImageShadow = styled.div`
  margin: 0 auto;
  ${({ theme }) => getLinearShadow('100%', '100%', '-16px', theme.spacing.ml)}
  padding: 0 ${({ theme }) => theme.spacing.ms};
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: 33%;
  }
`
const ImageWrapper = styled.div`
  aspect-ratio: 8/11;
  border-radius: 283px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @supports not (aspect-ratio: 1) {
    padding-top: 137.5%;
  }
`
const Content = styled.div`
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-right: 8.33%;
    width: 45%;

    & > *:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.m};
    }
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.typo.h2}
  ${({ theme }) => theme.typo.pointBefore}

  &::before {
    margin-top: ${({ theme }) => theme.spacing.ms};
  }
`
export const Description = styled.div`
  padding-left: ${({ theme }) => theme.spacing.ms};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    padding-left: ${({ theme }) => theme.spacing.m};
  }
`
export const DescriptionBold = styled(Description)`
  ${({ theme }) => theme.typo.bold}

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`
