import { SWRConfiguration } from 'swr'

import { getPropertyResults } from '@/services/api-graphql-property'
import { IPropertyKey, IPropertyKeySlug, IPropertyValue } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'
import { enrichPropertyKeys } from '@/utils/decorator'
import { swrImmutableConfig } from '@/utils/swr'

import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__PROPERTY_RESULTS = 'property_results'

export const PROPERTY_KEY_SLUGS_1 = [
  'area',
  'discipline',
  'lesson-type',
  'user-objective',
  'duration-lesson',
  'duration-video',
  'timeslot',
  'level',
  'intensity',
] as IPropertyKeySlug[]

export interface IReturnType<T = IPropertyValue> {
  propertyListLoading: boolean
  propertyList: IPropertyKey<T>[]
  propertyListError: HTTPError
}

export const useSWRPropertyResults = <T extends IPropertyValue>(
  propertyKeySlugs: IPropertyKeySlug[],
  swrConfig: SWRConfiguration = swrImmutableConfig
): IReturnType<T> => {
  const { data, error, isLoading } = useSWRCustom<IPropertyKey<T>[]>(
    [SWR_KEY__PROPERTY_RESULTS],
    getPropertyResults,
    {
      slugs: propertyKeySlugs,
    },
    swrConfig
  )

  return {
    propertyListLoading: !data && !error,
    propertyList: data && enrichPropertyKeys(data),
    propertyListError: error,
  }
}
