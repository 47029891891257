import { IPropertyKeySlug, IPropertyValue } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'

import { PROPERTY_KEY_SLUGS_1, useSWRPropertyResults } from './swr/useSwr-propertyResults'

interface IUsePropertyValueSharedResultsReturnType<T = IPropertyValue> {
  propValueListLoading: boolean
  propValueList: T[]
  propValueListError: HTTPError
}

export const usePropertyValueSharedResults = <T extends IPropertyValue>(
  propKeySlug: string
): IUsePropertyValueSharedResultsReturnType<T> => {
  const { propertyListLoading, propertyList, propertyListError } =
    useSWRPropertyResults<T>(PROPERTY_KEY_SLUGS_1)

  return {
    propValueListLoading: propertyListLoading,
    propValueList: propertyList?.find((propKey) => propKey.slug === propKeySlug)
      .propValues,
    propValueListError: propertyListError,
  }
}

export const usePropertyValueResults = <T extends IPropertyValue>(
  propKeySlug: IPropertyKeySlug
): IUsePropertyValueSharedResultsReturnType<T> => {
  const { propertyListLoading, propertyList, propertyListError } =
    useSWRPropertyResults<T>([propKeySlug])

  return {
    propValueListLoading: propertyListLoading,
    propValueList: propertyList?.[0].propValues,
    propValueListError: propertyListError,
  }
}
